<template>
  <div class="fixed top-2 right-0 z-50">
    <TransitionGroup>
      <div v-for="notification in notifications">
        <div class="z-50 mb-2 mx-auto items-center w-auto text-center min-w-sm max-w-sm px-8 py-2 text-gray-500 bg-white divide-x  rounded-lg shadow-md">
          {{ getIcon(notification.type) }}
          {{notification.message}}
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>


<script setup>
  import { storeToRefs } from 'pinia';
  import { useToastStore, NotificationType } from '../../stores/toast_store';
  const toastStore = useToastStore();
  const { notifications } = storeToRefs(toastStore);

  function getIcon(type){
    return type === NotificationType.Success ? "✅" : type === NotificationType.Error ? "❌" : "🔍";
  }
</script>

<style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>

