<template>
  <section class="p-4">
    <div class="flex flex-col text-center items-center justify-center pt-12">
      <h1 class="text-4xl font-bold">You're done!</h1>
      <p class="text-xl mt-4">
        You'll get your daily Text Tide Report via text message each morning around 8am.
      </p>
      <p class="text-xl my-4">
        Tell your friends about it!
      </p>
      <a class="text-white font-bold py-2 px-4 rounded text-2xl bg-blue-500" href="sms:&body=Check out TideTexts.com for free, daily tide reports!">Share Now</a>
      
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { TiderStore } from "@/front/stores/tider_store.js";
const store = TiderStore();
const router = useRouter();

const phone = ref('3215141160');
const code = ref('');

const submit = () => {
  store.verify({ code: code.value }).then((response)=> {
    console.log("Tider created", response.data)
    router.push('/setup')
  })
}

const handleValidate = (e) => {
  console.log("Validate", e);
  valid = e.valid
  validation = e
}

</script>
