<template>
  <section>
    <div class="flex flex-col text-center items-center justify-center pt-12">
      <h1 class="text-4xl font-bold">Finish Setup</h1>
      <p class="text-xl mt-4">
        Final step to complete setup!
      </p>
      <div class="w-full max-w-72 my-2">
        <label for="first_name" class="text-xl font-bold">First Name</label>
        <input v-model="first_name" type="text" autofocus="true" class="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Kelly" required />
      </div>

      <div class="w-full max-w-72 my-2">
        <label for="last_name" class="text-xl font-bold">Last Name</label>
        <input v-model="last_name" type="text" autofocus="true" class="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Slater" required />
      </div>

      <div class="w-full max-w-72 my-2">
        <label for="last_name" class="text-xl font-bold">Email</label>
        <input v-model="email" type="text" autofocus="true" class="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Slater" required />
      </div>

      <div class="w-full max-w-72 my-4">
        <label for="last_name" class="text-xl font-bold">What are you interested in?</label>
        <p> Check one or more options</p>
        <div class="grid grid-cols-2 ml-4">  
          <div class=" items-center text-left my-2">
            <input checked id="checked-checkbox" type="checkbox" v-model="fishing" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded">
            <label for="checked-checkbox" class="ml-2 text-2xl">Fishing</label>
          </div>
          <div class=" items-center text-left my-2">
            <input checked id="checked-checkbox" type="checkbox" v-model="boating" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded">
            <label for="checked-checkbox" class="ml-2 text-2xl">Boating</label>
          </div>
          <div class=" items-center text-left">
            <input checked id="checked-checkbox" type="checkbox" v-model="surfing" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-3000 rounded">
            <label for="checked-checkbox" class="ml-2 text-2xl">Surfing</label>
          </div>
          <div class=" items-center text-left">
            <input checked id="checked-checkbox" type="checkbox" v-model="diving" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded">
            <label for="checked-checkbox" class="ml-2 text-2xl">Diving</label>
          </div>
        </div>
      </div>
      <button @click="submit" :disabled="email.length == 0 || first_name.length == 0 || last_name.length == 0 || !(fishing || boating || surfing || diving)" class="text-white font-bold py-2 px-4 rounded text-2xl bg-blue-500">
        Finish 
      </button>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { TiderStore } from "@/front/stores/tider_store.js";
const store = TiderStore();
const router = useRouter();

const email = ref('');
const first_name = ref('');
const last_name = ref('');
const fishing = ref(false);
const boating = ref(false);
const surfing = ref(false);
const diving = ref(false);

const submit = () => {
  console.log("Submit")
  store.finalize({ email: email.value, first_name: first_name.value, last_name: last_name.value, fishing: fishing.value, boating: boating.value, surfing: surfing.value, diving: diving.value }).then((response)=> {
    console.log("Tider finalized", response.data)
    router.push('/success')
  })
}

const handleValidate = (e) => {
  console.log("Validate", e);
  valid = e.valid
  validation = e
}

</script>
