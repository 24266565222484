<template>
  <section class="flex h-screen px-3 lg:px-6">
    <Transition>

    <div v-if="showing" class="m-auto h-screen overflow-y-auto pt-20 mb-20 items-center text-left bg-white z-10 p-6 rounded-xl xs:max-w-md max-w-2xl lg:p-12 shadow-2xl ">
      <div>
        <h1 class="text-5xl leading-tight font-bold">Privacy Policy</h1></div>

        <h2>Introduction</h2>
        <p>Welcome to TideTexts ("we," "our," or "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our service. By using TideTexts, you consent to the data practices described in this policy. If you do not agree with the terms of this Privacy Policy, please do not use our service.</p>

        <h2>Information We Collect</h2>

        <h3>Personal Information</h3>
        <p>When you sign up for TideTexts, we may collect the following personal information:</p>
        <ul>
          <li><strong>Phone Number</strong>: To send you daily SMS messages with local tidal and weather information.</li>
          <li><strong>Email:</strong>: We use email as a backup method for account recovery.</li>
          <li><strong>Name:</strong>: To personalize our communications with you</li>
          <li><strong>Interests:</strong>: To tailor our messages to you based on your interests</li>
        </ul>

        <h3>Usage Information</h3>
        <p>We may also collect non-personal information about how you interact with our service, including:</p>
        <ul>
            <li><strong>Log Data</strong>: Information such as your device's Internet Protocol (IP) address, browser type, browser version, the pages of our service that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.</li>
            <li><strong>Cookies and Tracking Technologies</strong>: We may use cookies and similar tracking technologies to track the activity on our service and hold certain information.</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for various purposes, including:</p>
        <ul>
            <li>To provide and maintain our service.</li>
            <li>To send you daily SMS messages with local tidal and weather information.</li>
            <li>To send occasional sponsored messages.</li>
            <li>To notify you about changes to our service.</li>
            <li>To provide customer support.</li>
            <li>To gather analysis or valuable information so that we can improve our service.</li>
        </ul>

        <h2>Information Sharing and Disclosure</h2>
        <p>We will not share your personal information with any third parties without your explicit consent, except in the following circumstances:</p>
        <ul>
            <li><strong>Legal Requirements</strong>: If we are required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
            <li><strong>Service Providers</strong>: We may employ third-party companies and individuals to facilitate our service, provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used.</li>
        </ul>

        <h2>Data Security</h2>
        <p>The security of your data is important to us. We strive to use commercially acceptable means to protect your personal information, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we aim to protect your personal information, we cannot guarantee its absolute security.</p>

        <h2>Your Choices and Rights</h2>
        <p>You have the following rights regarding your personal information:</p>
        <ul>
            <li><strong>Access and Update</strong>: You can access and update your personal information by contacting us.</li>
            <li><strong>Opt-Out</strong>: You can opt-out of receiving SMS messages at any time by following the unsubscribe instructions provided in the messages.</li>
            <li><strong>Data Deletion</strong>: You can request the deletion of your personal information by contacting us.</li>
        </ul>

        <h2>Retention of Data</h2>
        <p>We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your personal information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

        <h2>Children’s Privacy</h2>
        <p>Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to remove that information from our servers.</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <h2>Contact Information</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>Email: hey@tidetexts.com</p>

        <p>Thank you for using TideTexts!</p>
        <p>This Privacy Policy is effective as of July 16th, 2024.</p>
    </div>
  </Transition>

  </section>
  <div id="images" class="absolute w-full h-full left-0 top-0 text-center text-2xl lg:text-4xl opacity-60">
    <div v-for="row in 20" class="grid grid-cols-12" style="height: 5%">
      <div v-for="i in 12" class="justify-center align-center emoji flex items-center" :style="{ animationDelay: `${i * 0.2}s` }"> {{ i%2== row%2 ? shuffle(emojis)[i] : '' }} </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { TiderStore } from "@/front/stores/tider_store.js";
const store = TiderStore();
const router = useRouter();

var showing = ref(false)

const phone = ref('');
var validation = ref({});
var valid = ref(false);
var loading = ref(false);

const emojis = [
  '🌊', 
  '🛥️', 
  '🦈', 
  '🤿', 
  '🏄‍♂️', 
  '🎣', 
  '⛵️', 
  '🐠', 
  '😎', 
  '🐬', 
  '🐙', 
  '🦐', 
  '☀️', 
  '🌚', 
  '🌙', 
  '🍣', 
  '🏄', 
  '🦀',
  '🦭',
  '🏖️',
  '👙',
  '🧜',
  '🥥',
  '🦑',
  '🐋',
  '🛶',
  '🌎',
  '🌝',
  '🌜',
  '🌛',
  '🌞',
]


const submit = () => {
  loading = true
  store.createTider({ phone: validation.number }).then((response)=> {
    router.push('/confirm')
  })
}

const shuffle = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array
  }

const handleValidate = (e) => {
  console.log("Validate", e);
  valid = e.valid
  validation = e
}


onMounted(() => {
  setTimeout(() => {
    showing.value = true
    console.log("Showing now " + showing)
  }, 10)
})

</script>

<style scoped>
  p {
    margin: 20px 0;
    line-height: 2em;;
  }
  h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 20px;
  }
  h3 {
    font-size: 1.25em;;
    font-weight: bold;
    margin-top: 20px;
  }

  li {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .emoji {
      border: none;
      background: none;
      animation: wave 8s ease-in-out infinite;
      transition: transform 1s ease, background-image 1s ease;
    }

    .v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


    @keyframes wave {
  0%, 100% {
    transform: translate(0);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(10px);
  }
  75% {
    transform: translateY(-20px);
  }
}
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
    }

    70% {
      transform: scale(1);
      /* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
    }

    100% {
      transform: scale(0.95);
      /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    }
  }
</style>